<template>
  <div>
    <general-table
      :api-url="APIURL"
      :edit-component="editComponent"
      :delete-content="true"
      :block-content="false"
      :edit-content="true"
      :view-content="false"
      :selectable="false"
      :columns="columns"
      :type="type"
      :per-page="perPage"
    >
      <template #cell(engineers)="data">
        <slot
          name="actions"
          v-bind="data"
        >
          <div style="text-align: center;">
            <feather-icon
              icon="UsersIcon"
              size="16"
              class="mr-50 cursor-pointer"
              @click="ApprovalRow(data.item.engineers)"
            />
          </div>
        </slot>
      </template>
      <template #cell(components)="data">
        <slot
          name="info"
          v-bind="data"
        >
          <div class="text-center">
            <span title="View">
              <feather-icon
                icon="EyeIcon"
                class="cursor-pointer"
                @click="
                  $router.push({
                    name: 'components',
                    params: { CM_id: data.item.id, Components_Name: data.item.name.en }
                  })
                "
              />
            </span>
          </div>
        </slot>
      </template>
    </general-table>
  </div>
</template>

<script>
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  data() {
    // const apiUrl = `certificate/${this.$route.params.MCR_id}`
    // console.log('API URL:', apiUrl)

    return {
      APIURL: `certificate/${this.$route.params.MCR_id}`,
      // addType: 'Add Certificate',
      // addComponentName: 'add-certificate',
      editComponent: 'edit-certificate',
      viewContent: true,
      type: 'page',
      perPage: 50,
      columns: [
        { key: 'id', sortable: false },
        { key: 'name.ar', label: 'Certificate Name Ar' },
        { key: 'name.en', label: 'Certificate Name En' },
        { key: 'description.ar', label: 'Description Certificate Ar' },
        { key: 'description.en', label: 'Description Certificate En' },
        { key: 'components', label: 'show components' },
        { key: 'engineers', label: 'Engineers' },
        { key: 'actions' },
      ],
    }
  },
  methods: {
    formatEngineerNames(names) {
      return `
         <table style="border: 0.5px solid; border-collapse: collapse; margin-top: 18px!important; text-align: center; margin: auto;">
          <tr style="background-color: #f3f2f7">
            <th style="border: 0.5px solid; padding: 8px; text-align: left;">#</th>
            <th style="border: 0.5px solid; padding: 8px; text-align: left;">Engineer Names</th>
          </tr>
          ${names
    .split(', ')
    .map(
      (name, index) => `
            <tr>
              <td style="border: 1px solid; padding: 8px;">${index + 1}</td>
              <td style="border: 1px solid; padding: 8px;">${name}</td>
            </tr>`,
    )
    .join('')}
        </table>
      `
    },
    ApprovalRow(name) {
      this.$swal({
        title: 'Engineer List',
        html: this.formatEngineerNames(name),
        // text: this.formatEngineerNames(name),
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Close',
      })
    },
  },
}
</script>

<style></style>
